import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { cssProperties } from "../utils/commonCssProperties";
import { Box } from "@mui/material";

export default function VerticalTable({ tableData, columnData }) {
  let [rows, setRows] = React.useState(tableData);

  React.useEffect(() => {
    setRows(tableData);
  }, [tableData]);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "", 
          width: "100%",
          mb: 2,
          pr: 2,
          mt: 4,
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            background: "none",
            border: `0px solid ${cssProperties.bordercolor.primary2blue}`,
            boxShadow: "none",
           
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="vertical table">
            <TableBody>
              {rows.length > 0 ? (
                // Iterate through each column and display header and data vertically
                columnData?.map((param, colIndex) => (
                  <TableRow
                    key={colIndex}
                    sx={{
                        border: `1px solid ${cssProperties.bordercolor.primary2blue}`,
                    }}
                  >
                    {/* Display column header (name) */}
                    <TableCell
                      sx={{
                        textAlign: "end",
                        color: cssProperties.bordercolor.tableblack,
                        fontWeight: "500", // Header bold
                        width:400,
                        border: `1px solid ${cssProperties.bordercolor.primary2blue}`,

                      }}
                    >
                      {param.name}
                    </TableCell>

                    {/* Display data */}
                    <TableCell
                      sx={{
                        textAlign: "start",
                        color: cssProperties.bordercolor.tableblack,
                        fontWeight: "bold",
                        border: `0px solid ${cssProperties.bordercolor.primary2blue}`,
                        
                      }}
                    >
                      {param["render"]
                        ? param["render"](rows[0][param.dataIndex], rows[0])
                        : rows[0][param.dataIndex]}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={2}
                    sx={{
                      textAlign: "center",
                      color: cssProperties.bordercolor.primary2blue,
                    }}
                  >
                    No Data
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
