import { all, call, takeEvery, put, delay } from "redux-saga/effects";
import customerActions from "./actions";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import commonActions from "../common/actions";

const customerSagas = function* () {
    yield all([
        yield takeEvery(customerActions.GET_REPORTS, getReports),
        yield takeEvery(customerActions.GET_PAYMENT_HISTORY, getPaymentHistory),

    ])
};

const getReports = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/backoffice/customer/reports/${payload?.courseid}`)
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: customerActions.SET_REPORTS, payload: result?.data?.data });
        };
    } catch (err) {
        console.log(err);
    };
};

const getPaymentHistory = function* (data) {
    const { payload } = data;

    try {
        const result = yield call(() =>
            axios.get(`${API_URL}/backoffice/customer/payment-history/${payload?.courseid}`)
        );
        if (result?.data?.statusCode === 200) {
            yield put({ type: customerActions.SET_PAYMENT_HISTORY, payload: result?.data?.data });
        };
    } catch (err) {
        console.log(err);
    };
};



export default customerSagas;