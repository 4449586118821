import React, { useEffect, useState } from "react";
import { Typography, Card, Grid, Box, Select, MenuItem } from "@mui/material";
import DashboardTable from "../../common/dashboardTable";
import masterActions from "../../redux/master/actions";
import customerActions from "../../redux/customer/actions";
import { useDispatch, useSelector } from "react-redux";

let applicationStatusColumns = [
  {
    name: "Application Status",
    dataIndex: "status",
  },
  {
    name: "Total",
    dataIndex: "candidatecount",
  },
];
let candidatureTypeColumns = [
  {
    name: "Candidature Type",
    dataIndex: "candidate_type",
  },
  {
    name: "Total",
    dataIndex: "candidate_count",
  },
];
let regionWiseColumns = [
  {
    name: "Region ",
    dataIndex: "region_type",
  },
  {
    name: "Total",
    dataIndex: "region_count",
  },
];
let categoryWiseColumns = [
  {
    name: "Category",
    dataIndex: "category_type",
  },
  {
    name: "Total",
    dataIndex: "category_count",
  },
];
let genderWiseColumns = [
  {
    name: "Gender",
    dataIndex: "gender_type",
  },
  {
    name: "Total",
    dataIndex: "gender_count",
  },
];
let PWDWiseColumns = [
  {
    name: "PWD Type",
    dataIndex: "pwd_type",
  },
  {
    name: "Total",
    dataIndex: "pwd_count",
  },
];
let stateWiseColumns = [
  {
    name: "state",
    dataIndex: "state_name",
  },
  {
    name: "Total",
    dataIndex: "state_count",
  },
];
let districtWiseColumns = [
  {
    name: "district",
    dataIndex: "district_name",
  },
  {
    name: "Total",
    dataIndex: "district_count",
  },
];
let religionWiseColumns = [
  {
    name: "religion",
    dataIndex: "religion_type",
  },
  {
    name: "Total",
    dataIndex: "religion_count",
  },
];
let stepWiseColumns = [
  {
    name: "step",
    dataIndex: "status_description",
  },
  {
    name: "Total",
    dataIndex: "candidatecount",
  },
];
let examcenterWiseColumns = [
  {
    name: "Exam center",
    dataIndex: "district",
  },
  {
    name: "Total",
    dataIndex: "candidatecount",
  },
];
const CustomerDashboard = () => {
  const dispatch = useDispatch();
  const [courseid, setCourseId] = useState(0);
  const { courseList } = useSelector((state) => state.masterReducer);
  const { reports } = useSelector((state) => state.customerReducer);
  console.log(reports, "rpts");

  useEffect(() => {
    dispatch({ type: masterActions.GET_COURSE_LIST });
  }, []);
  const onChangeCourse = (e) => {
    setCourseId(e.target.value); 
  };
  useEffect(() => {
    courseid &&
      dispatch({
        type: customerActions.GET_REPORTS,
        payload: { courseid: courseid },
      });
  }, [courseid]);
  console.log(reports?.religionwisereport,"religionwisereport");
  console.log(reports?.statewisereport,"statewisereport");
  
  return (
    <>
      <Card sx={{ p: 2 }}>
        <Typography>Dashboard</Typography>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Box display="flex" justifyContent="flex-end">
              <Select
                size="small"
                onChange={onChangeCourse}
                value={courseid}
                defaultValue={0}
                sx={{ width: "100%", maxWidth: "200px" }}
              >
                <MenuItem value={0} selected>
                  Courses
                </MenuItem>
                {courseList?.all?.map((course, i) => (
                  <MenuItem key={i} value={course.courseid}>
                    {course.coursename}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Grid item sm={12}>
            {/* Application status wise report */}
            <Card sx={{py:2}}>
              <Typography sx={{ textAlign: "center", py: 1 }}>
                Application status wise report
              </Typography>
              <DashboardTable
                columnData={applicationStatusColumns}
                tableData={reports?.applicationstatuswisereports}
              />
            </Card>
          </Grid>
          <Grid item md={6} sm={12}>
            {/* // Candidature Type Wise Report */}
            <Card sx={{py:2}}>
              <Typography sx={{ textAlign: "center", py: 1 }}>
                Candidature Type Wise Report
              </Typography>
              <DashboardTable
                columnData={candidatureTypeColumns}
                tableData={reports?.candidaturetypewisereport}
              />
            </Card>
            {/* //category wise */}
            <Card sx={{py:2}}>
              <Typography sx={{ textAlign: "center", py: 1 }}>
                Category Wise Report
              </Typography>
              <DashboardTable
                columnData={categoryWiseColumns}
                tableData={reports?.categorywisereport}
              />
            </Card>
            {/* //State Wise Report */}
            <Card sx={{py:2}}>
              <Typography sx={{ textAlign: "center", py: 1 }}>
                State Wise Report
              </Typography>
              <DashboardTable
                columnData={stateWiseColumns}
                tableData={reports?.statewisereport}
              />
            </Card>
            {/* //Religion Wise Report */}
            <Card sx={{py:2}}>
              <Typography sx={{ textAlign: "center", py: 1 }}>
                Religion Wise Report
              </Typography>
              <DashboardTable
                columnData={religionWiseColumns}
                tableData={reports?.religionwisereport}
              />
            </Card>
            {/* Exam Center Wise Report */}
            <Card sx={{py:2}}>
              <Typography sx={{ textAlign: "center", py: 1 }}>
                Exam Center Wise Report
              </Typography>
              <DashboardTable
                columnData={examcenterWiseColumns}
                tableData={reports?.examcenterwisereport}
              />
            </Card>
          </Grid>
          <Grid item md={6} sm={12}>
            {/* Region Wise Report */}
            <Card sx={{py:2}}>
              <Typography sx={{ textAlign: "center", py: 1 }}>
                Region Wise Report
              </Typography>
              <DashboardTable
                columnData={regionWiseColumns}
                tableData={reports?.regionwisereport}
              />
            </Card>
            {/* Gender Wise Report */}
            <Card sx={{py:2}}>
              <Typography sx={{ textAlign: "center", py: 1 }}>
                Gender Wise Report
              </Typography>
              <DashboardTable
                columnData={genderWiseColumns}
                tableData={reports?.genderwisereport}
              />
            </Card>
            {/* PWD Wise Report */}
            <Card sx={{py:2}}>
              <Typography sx={{ textAlign: "center", py: 1 }}>
                PWD Wise Report
              </Typography>
              <DashboardTable
                columnData={PWDWiseColumns}
                tableData={reports?.pwdwisereport}
              />
            </Card>
            {/* District Wise Report */}
            <Card sx={{py:2}}>
              <Typography sx={{ textAlign: "center", py: 1 }}>
                District Wise Report
              </Typography>
              <DashboardTable
                columnData={districtWiseColumns}
                tableData={reports?.districtwisereport}
              />
            </Card>
            {/* Step Wise Report */}
            <Card sx={{py:2}}>
              <Typography sx={{ textAlign: "center", py: 1 }}>
                Step Wise Report
              </Typography>
              <DashboardTable
                columnData={stepWiseColumns}
                tableData={reports?.stepwisereport}
              />
            </Card>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default CustomerDashboard;
