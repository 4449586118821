import React, { useState } from "react";
import cetimg from "../assets/images/navbar/cet_img.png";
import { CiLogout } from "react-icons/ci";
import {
  PiHand,
  PiTicket,
  PiUserGearLight,
  PiUserList,
  PiUserListBold,
  PiUserPlus,
} from "react-icons/pi";
import { TbFileSearch, TbLayoutDashboardFilled, TbUserSearch } from "react-icons/tb";
import {
  Box,
  AppBar,
  Divider,
  Typography,
  Drawer,
  Portal,
} from "@mui/material";
import { cssProperties } from "../utils/commonCssProperties";
import Cookies from "js-cookie";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import authActions from "../redux/auth/actions";
import { IoPersonOutline } from "react-icons/io5";
import { capitalizeFirstLetter } from "../utils/validation";
import { MdOutlineArticle, MdOutlineManageAccounts, MdOutlinePayments } from "react-icons/md";

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen = true) => {
    console.log("in fn", newOpen);
    setOpen(newOpen);
  };
  const onMouseLeave = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const currentPosition = { x: e.clientX, y: e.clientY };

    const horizontalExit =
      currentPosition.x < rect.left || currentPosition.x > rect.right;
    const verticalExit =
      currentPosition.y < rect.top || currentPosition.y > rect.bottom;
    if (!horizontalExit) {
      setOpen(false);
    }
  };
  const { backofficeTokenDetails } = useSelector((state) => state.authReducer);

  // const roleSelectionItems = (backofficeTokenDetails?.role?.role || []).map((role, index) => ({
  //   name: role,
  //   role: role,
  //   icon: <IoPersonOutline size={24} />,
  //   onClick: () => {
  //     dispatch({Ge
  //       type: authActions.ROLE_TOKEN_GENERATION,
  //       payload: { role: role, navigate: navigate },
  //     });
  //   },
  // }));

  const defaultItems = [
    {
      name: "Dashboard",
      icon: <TbLayoutDashboardFilled size={24} />,
      path: `${
        (location?.pathname).includes("/admin")
          ? `/admin/home`
          : `/${Cookies.get("mhet_mst_project")}/home`
      }`,
      // path: `/${Cookies.get("mhet_mst_project")}/home`,
      role: "All",
      subPath: [
        `${
          backofficeTokenDetails?.role?.role?.length > 1
            ? `/${Cookies.get("mhet_mst_project")}/role-selection`
            : backofficeTokenDetails?.role?.role?.find(
                (val) => val === "Grievance Resolution"
              )
            ? `/${Cookies.get("mhet_mst_project")}/ticket-details`
            : backofficeTokenDetails?.role?.role?.find(
                (val) => val === "Objection Reviewer"
              )
            ? `/${Cookies.get("mhet_mst_project")}/view-objections`
            : `/${Cookies.get("mhet_mst_project")}/home`
        }`,
        "/admin/home",
      ],
    },

    {
      name: "Check Payment History",
      icon: <MdOutlinePayments size={24} />,
      path: `${
        (location?.pathname).includes("/admin")
          ? `/admin/home`
          : `/${Cookies.get("mhet_mst_project")}/check-payment-history`
      }`,
      role: "Customer",
    },

    {
      name: "Check Application Status",
      icon: <TbFileSearch size={24} />,
      path: `${
        (location?.pathname).includes("/admin")
          ? `/admin/home`
          : `/${Cookies.get("mhet_mst_project")}/check-application-status`
      }`,
      role: "Customer",
    },

    {
      name: "Search Candidate",
      icon: <TbUserSearch size={24} />,
      path: `${
        (location?.pathname).includes("/admin")
          ? `/admin/home`
          : `/${Cookies.get("mhet_mst_project")}/search-candidate`
      }`,
      role: "Customer",
    },
    {
      name: "User Management",
      icon: <MdOutlineManageAccounts size={24} />,
      path: `/${Cookies.get("mhet_mst_project")}/users`,
      role: "Super Admin",
    },
    {
      name: "Master Management",
      icon: <MdOutlineArticle size={24} />,
      path: `/${Cookies.get("mhet_mst_project")}/master-list`,
      role: "Super Admin",
    },
    {
      name: "Master Forms",
      icon: <MdOutlineArticle size={24} />,
      // path: `/${Cookies.get("mhet_mst_project")}/master-forms-list`,
      role: "Super Admin",
      onClick: toggleDrawer,
      onMouseEnter: toggleDrawer,
      onMouseLeave: onMouseLeave,
    },
    {
      name: "Course Master",
      icon: <PiUserListBold size={24} />,
      path: `/${Cookies.get(
        "mhet_mst_project"
      )}/course-management?page=courselist`,
      role: "Super Admin",
      subPath: [`/${Cookies.get("mhet_mst_project")}/course-management`],
    },
    {
      name: "View Objections",
      icon: <PiHand size={24} />,
      path: `/${Cookies.get("mhet_mst_project")}/view-objections`,
      role: "Objection Coordinator",
    },
    // {
    //   name: "Communication Management",
    //   icon: <PiUserPlus size={24} />,
    //   path: `/${Cookies.get("mhet_mst_project")}/communication-management`,
    //   role: "Super Admin",
    // },
    // {
    //   name: "Backoffice Dashboard", icon: <PiUserPlus size={24} />,
    //   path: `/${Cookies.get('mhet_mst_project')}/backoffice-dashboard`, role: 'Super Admin'
    // },
    {
      name: "Allocate Venue",
      icon: <PiUserPlus size={24} />,
      path: `/${Cookies.get("mhet_mst_project")}/allocate-venue-dashboard`,
      role: "Back Office",
    },
    {
      name: "Force Push",
      icon: <PiUserPlus size={24} />,
      path: `/${Cookies.get("mhet_mst_project")}/force-push`,
      role: "Back Office",
    },
    {
      name: "Roll No Allocation",
      icon: <PiUserPlus size={24} />,
      path: `/${Cookies.get("mhet_mst_project")}/roll-no-management`,
      role: "Back Office",
    },
    {
      name: "Tickets",
      icon: <PiTicket size={24} />,
      path: `/${Cookies.get("mhet_mst_project")}/ticket-list`,
      role: "Grievance Coordinator",
      subPath: [
        `${
          backofficeTokenDetails?.role?.role?.find(
            (val) => val === "Grievance Coordinator"
          )
            ? `/${Cookies.get("mhet_mst_project")}/ticket-details`
            : `/${Cookies.get("mhet_mst_project")}/home`
        }`,
      ],
    },
    // {
    //   name: "Course Management",
    //   icon: <PiUserPlus size={24} />,
    //   path: `/${Cookies.get("mhet_mst_project")}/course-management?page=courselist`,
    //   role: "Super Admin",
    // },
    // {
    //   name: "Tickets",
    //   icon: <PiTicket size={24} />,
    //   path: `${
    //     backofficeTokenDetails?.role?.role?.find(
    //       (val) =>
    //         val === "Grievance Coordinator" || val === "Grievance Resolution"
    //     )
    //       ? `/${Cookies.get("mhet_mst_project")}/ticket-list`
    //       : `/${Cookies.get("mhet_mst_project")}/helpdesk-ticket-list`
    //   }`,
    //   role: "Grievance Coordinator",
    //   role: "Grievance Resolution",
    //   role: "Grievance Help Desk",
    //   subPath: [
    //     `${
    //       backofficeTokenDetails?.role?.role?.find(
    //         (val) =>
    //           val === "Grievance Coordinator" || val === "Grievance Resolution"
    //       )
    //         ? `/${Cookies.get("mhet_mst_project")}/ticket-details`
    //         : `/${Cookies.get("mhet_mst_project")}/home`
    //     }`,
    //   ],
    // },

    // {
    //   ...(backofficeTokenDetails?.role?.role?.find(val => val === 'Grievance Resolution') && {
    //   name: "Tickets",
    //   icon: <PiTicket size={24} />,
    //   path: backofficeTokenDetails?.role?.role?.find(val => val === 'Grievance Resolution')
    //     ? `/${Cookies.get('mhet_mst_project')}/ticket-details`
    //     : null,
    //   role: 'Grievance Resolution'})
    // }
    // {
    //   name: "Tickets",
    //   icon: <PiTicket size={24} />,
    //   path: `/${Cookies.get("mhet_mst_project")}/ticket-list`,
    //   role: "Grievance Coordinator",
    // },
    {
      name: "Tickets",
      icon: <PiTicket size={24} />,
      path: `/${Cookies.get("mhet_mst_project")}/helpdesk-ticket-list`,
      role: "Grievance Help Desk",
    },
    {
      name: "Tickets",
      path: `/${Cookies.get("mhet_mst_project")}/helpdesk-ticket-list`,
    },
  ];

  // const items = location.pathname === `/${Cookies.get("mhet_mst_project")}/role-selection` ? roleSelectionItems : defaultItems;
  const handleLogout = () => {
    // sessionStorage.clear();
    if ((location?.pathname).includes("/admin")) {
      window.location.href = `/admin/`;
    } else {
      Cookies.remove(`mhet_mst_token`);
      window.location.href = `/${Cookies.get("mhet_mst_project")}/`;
    }
  };
  const masterFormItems = [
    { name: "gender", details: "Create and update gender master" },
    {
      name: "mother tongue",
      details: "Create and update mother tongue master",
    },
    { name: "religion", details: "Create and update religion master" },
    { name: "region", details: "Create and update region master" },
    { name: "nationality", details: "Create and update nationality master" },
    {
      name: "annual family income",
      details: "Create and update annual family income master",
    },
    { name: "state", details: "Create and update state master" },
    { name: "district", details: "Create and update district master" },
    { name: "taluka", details: "Create and update taluka master" },
    { name: "village", details: "Create and update village master" },
    { name: "category", details: "Create and update category master" },
    { name: "caste", details: "Create and update caste master" },
    { name: "pwd type", details: "Create and update pwd type master" },
    {
      name: "equivalent board",
      details: "Create and update equivalent board master",
    },
    { name: "board", details: "Create and update board master" },
    { name: "language", details: "Create and update language master" },
    { name: "minority", details: "Create and update minority master" },
    {
      name: "grievance category",
      details: "Create and update grievance category master",
    },
    {
      name: "grievance type",
      details: "Create and update grievance type master",
    },
    {
      name: "document type",
      details: "Create and update document type master",
    },
    {
      name: "course category",
      details: "Create and update document type master",
    },
    {
      name: "building type",
      details: "Create and update building type master",
    },
    { name: "venue type", details: "Create and update venue type master" },
    { name: "admit card", details: "Create and update admit card" },
    { name: "course tab", details: "Create and update course tab master" },
    { name: "course fields", details: "Create and update course fields master" },
  ];
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "85px",
          height: "100vh",
          left: 0,
          backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
          scrollbarWidth: "thin",
          justifyContent: "space-between",
          padding: "24px 12px 24px 12px",
          boxShadow: "none",
          zIndex: 100,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <img
            style={{ width: "36px", height: "36px" }}
            src={cetimg}
            alt="logo"
          />
          <Divider sx={{ width: "70%", borderWidth: "1px" }} />
        </Box>
        <Box
          sx={{
            gap: "17px",
            margin: "10px 0px",
            height: "80vh",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* eslint-disable-next-line */}
            {defaultItems
              // {items
              ?.filter((val) => {
                if (
                  val?.role === "All" ||
                  (backofficeTokenDetails?.role?.role?.length === 1 &&
                    backofficeTokenDetails?.role?.role?.find(
                      (r) => r?.toLowerCase() === val?.role?.toLowerCase()
                    ))
                ) {
                  return val;
                }
              })
              ?.map((item, index) => {
                let isActive =
                  location.pathname === item.path ||
                  (Array.isArray(item.subPath) &&
                    item.subPath.some((subPath) =>
                      (location?.pathname).includes(subPath)
                    ));
                // console.log(location.pathname , `/${Cookies.get("mhet_mst_project")}/master-forms`,'path check')
                if (
                  item.name === "Master Forms" &&
                  location.pathname ===
                    `/${Cookies.get("mhet_mst_project")}/master-forms`
                ) {
                  isActive = true;
                }
                // {
                //   console.log(
                //     "isActive",
                //     isActive,
                //     item.subPath,
                //     location?.pathname
                //   );
                // }
                // const isActive = pathname === item.path || pathname + search === item.path || (Array.isArray(item.subPath) && item.subPath.some(subPath => (pathname + search).includes(subPath)));
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      padding: "10px 0px",
                      gap: "10px",
                      cursor: "pointer",
                      "& .item-icon": {
                        backgroundColor: isActive
                          ? `${cssProperties?.hoverbgcolor?.sidebar}`
                          : "inherit",
                        borderRadius: "52px",
                        color: isActive
                          ? `${cssProperties?.color?.primary}`
                          : `${cssProperties?.color?.textsecondary}`,
                      },
                      "& .item-name": {
                        color: isActive
                          ? `${cssProperties?.color?.primary}`
                          : `${cssProperties?.color?.textsecondary}`,
                      },
                      "&:hover": {
                        "& .item-icon": {
                          backgroundColor: `${cssProperties?.hoverbgcolor?.sidebar}`,
                          borderRadius: "52px",
                          color: `${cssProperties?.color?.primary}`,
                        },
                        "& .item-icon, & .item-name": {
                          color: `${cssProperties?.color?.primary}`,
                        },
                      },
                    }}
                    onClick={() => {
                      if (item?.path) {
                        navigate(item.path);
                      }
                      if (item?.onClick) {
                        item.onClick();
                      }
                    }}
                    onMouseEnter={() => {
                      if (item?.onMouseEnter) {
                        item.onMouseEnter(true);
                      }
                    }}
                    onMouseLeave={(e) => {
                      if (item?.onMouseLeave) {
                        item.onMouseLeave(e);
                      }
                    }}
                  >
                    <Box
                      className={"item-icon"}
                      sx={{
                        color: `${cssProperties?.color?.textsecondary}`,
                        width: "70%",
                        textAlign: "center",
                      }}
                      key={index}
                    >
                      {item.icon}
                    </Box>
                    <Typography
                      className={"item-name"}
                      sx={{
                        color: `${cssProperties?.color?.textsecondary}`,
                        fontSize: "12px",
                        textWrap: "wrap",
                        textAlign: "center",
                        lineHeight: "13.2px",
                      }}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                );
              })}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "17px",
            margin: "10px 0px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "1rem",
              padding: "0px 0px",
              gap: "10px",
              cursor: "pointer",
              "&:hover": {
                "& .item-icon": {
                  backgroundColor: `${cssProperties?.hoverbgcolor?.sidebar}`,
                  borderRadius: "52px",
                },
                "& .item-icon, & .item-name": {
                  color: `${cssProperties?.hoverfontcolor?.sidebar}`,
                },
              },
            }}
            onClick={handleLogout}
          >
            <Box
              className={"item-icon"}
              sx={{
                color: `${cssProperties?.color?.textsecondary}`,
                width: "70%",
                textAlign: "center",
              }}
            >
              <CiLogout size={24} />
            </Box>
            <Typography
              className={"item-name"}
              sx={{
                color: `${cssProperties?.color?.textsecondary}`,
                fontSize: "12px",
                textWrap: "wrap",
                textAlign: "center",
                lineHeight: "13.2px",
              }}
            >
              {"Logout"}
            </Typography>
          </Box>
        </Box>
      </AppBar>
      <Drawer
        sx={{ zIndex: 99 }}
        ModalProps={{ width: `calc(100% - 85px)`, left: "85px" }}
        transitionDuration={{
          enter: 650,
          exit: 450,
        }}
        PaperProps={{
          sx: { width: 150, left: "85px", scrollbarWidth: "thin" },
        }}
        //  onMouseEnter={() => toggleDrawer(true)} onMouseLeave={() => toggleDrawer(false)}
        open={open}
        onClose={() => toggleDrawer(false)}
        anchor="left"
      >
        <Box sx={{ marginTop: "12px", scrollbarWidth: "thin" }}>
          {masterFormItems.map((item, index) => {
            // console.log(location.search,location.search === `?form=${item?.name?.replace(/ /g, '')}`)
            const isActive =
              location.search === `?form=${item?.name?.replace(/ /g, "")}`;
            return (
              <Box
                key={index}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(
                    `/${Cookies.get(
                      "mhet_mst_project"
                    )}/master-forms?form=${item?.name?.replace(/ /g, "")}`
                  );
                  setOpen(false);
                }}
              >
                <Typography
                  className={"item-name"}
                  sx={{
                    fontSize: "12px",
                    textWrap: "wrap",
                    textAlign: "center",
                    lineHeight: "13.2px",
                    py: 1,
                    backgroundColor: isActive
                      ? `${cssProperties?.hoverbgcolor?.sidebar}`
                      : "inherit",
                    borderRadius: "52px",
                    color: isActive
                      ? `${cssProperties?.color?.primary}`
                      : `${cssProperties?.color?.textsecondary}`,
                    "&:hover": {
                      backgroundColor: `${cssProperties?.hoverbgcolor?.sidebar}`,
                      borderRadius: "52px",
                      color: `${cssProperties?.color?.primary}`,
                    },
                  }}
                >
                  {capitalizeFirstLetter(item.name)}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Drawer>
    </>
  );
}

export default Sidebar;
