import { Box, Typography } from "@mui/material";
import React from "react";
import CommonTable from "../../common/CommonTable";
import GreenButton from "../../common/button/greenButton";
import DataTable from "../../common/dataTable";
import VerticalTable from "../../common/verticalTable";

export default function Index() {

  let applicationColumns = [
    {
      name: "Application No",
      dataIndex: "applicationNo",
    },
    {
      name: "Candidate's Name",
      dataIndex: "candidateName",
    },
  ];

  const dataTableData = [
    {
      applicationNo: "2447000005",
      candidateName: "Nishant Janbandhu",
    },
  ];

  let applicationStatusColumns = [
    {
      name: "Step ID",
      dataIndex: "stepId",
    },
    {
      name: "Step Details",
      dataIndex: "stepDetails",
    },
    {
      name: "Status",
      dataIndex: "status",
      render: () => (
        <GreenButton
          title="complete"
        />
      ),
    },
  ];

  const applicationStatusData = [
    {
      stepId:"Step 1",
      stepDetails: "Registration/Personal Details",
      status: "",
    },
    {
      stepId:"Step 2",
      stepDetails: "Qualification Details",
      status: "",
    },
  ];


  return (
    <>
      <Box
        sx={{
          margin: "1rem auto",
          backgroundColor: "rgba(255, 255, 255, 1)",
          borderRadius: "8px",
          padding: "10px",
        }}
      >
      
        <Box sx={{ mb: 2 }}>
         <VerticalTable 
         columnData={applicationColumns}
         tableData={dataTableData}
         />
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "500",
              color: "black",
              fontSize: "20px",
              lineHeight: "24px",
              mb: 2,
              backgroundColor: "whitesmoke",
              padding: "8px",
              borderRadius: "4px",
            }}
          >
            Application Form Status{" "}
          </Typography>
        </Box>


        <CommonTable
          columnData={applicationStatusColumns}
          tableData={applicationStatusData}
        />
      </Box>
    </>
  );
}
