import { Box, Card, Grid, MenuItem, Select, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CommonTable from "../../common/CommonTable";
import BluePrimaryButton from "../../common/button/bluePrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import masterActions from "../../redux/master/actions";
import customerActions from "../../redux/customer/actions";

export default function CheckPaymentHistory() {
  const dispatch = useDispatch();
  const [courseid, setCourseId] = useState(0);
  const { courseList } = useSelector((state) => state.masterReducer);

  useEffect(() => {
    dispatch({ type: masterActions.GET_COURSE_LIST });
  }, []);
  const onChangeCourse = (e) => {
    setCourseId(e.target.value);
  };
  useEffect(() => {
    courseid &&
      dispatch({
        type: customerActions.GET_REPORTS,
        payload: { courseid: courseid },
      });
  }, [courseid]);

  let paymentHistoryColumns = [
    {
      name: "Sr. No",
      dataIndex: "srNo",
    },
    {
      name: "Reference No",
      dataIndex: "referenceNo",
    },
    {
      name: "Total Fee (₹)",
      dataIndex: "totalFee",
    },
    {
      name: "Payment Initiated On",
      dataIndex: "paymentInitiatedOn",
    },
    {
      name: "Payment Date",
      dataIndex: "paymentDate",
    },
    {
      name: "Payment Status",
      dataIndex: "paymentStatus",
    },
    {
      name: "Refund Status",
      dataIndex: "refundStatus",
    },
    {
      name: "Print Receipt",
      dataIndex: "printReceipt",
      render: () => (
        <BluePrimaryButton title="Print" variant="contained" color="primary" />
      ),
    },
  ];

  const paymentHistoryData = [
    {
      srNo: 1,
      referenceNo: "147000987423",
      totalFee: "2200.00",
      paymentInitiatedOn: "2023-01-01",
      paymentDate: "2023-01-02",
      paymentStatus: "Paid",
      refundStatus: "",
      printReceiptUrl: "",
    },
  ];

  return (
    <>
      <Box
        sx={{
          margin: "1rem auto",
          backgroundColor: "rgba(255, 255, 255, 1)",
          borderRadius: "8px",
          padding: "10px",
        }}
      >
        <Card sx={{ p: 2 }}>
          <Typography>Check Payment History</Typography>

          <Grid item sm={12}>
            <Box display="flex" justifyContent="flex-end">
              <Select
                size="small"
                onChange={onChangeCourse}
                value={courseid}
                defaultValue={0}
                sx={{ width: "100%", maxWidth: "200px" }}
              >
                <MenuItem value={0} selected>
                  Courses
                </MenuItem>
                {courseList?.all?.map((course, i) => (
                  <MenuItem key={i} value={course.courseid}>
                    {course.coursename}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>

          <Box sx={{ mb: 2, mt:2 }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "500",
                color: "black",
                fontSize: "20px",
                lineHeight: "24px",
                mb: 2,
                backgroundColor: "whitesmoke",
                padding: "8px",
                borderRadius: "4px",
              }}
            >
              Paid Transaction Details
            </Typography>
          </Box>

          <Box sx={{ mb: 2 }}>
            <Typography
              variant="subtitle1"
              sx={{ color: "red", fontWeight: "bold" }}
            >
              Instructions :
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "500",
                color: "red",
                fontSize: "16px",
                lineHeight: "24px",
                mb: 2,
                backgroundColor: "whitesmoke",
                border: "1px solid red",
                padding: "8px",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "24px", marginRight: "8px" }}>•</span>
              Receipt for Online / Netbanking payment can be taken by clicking
              on "Print" button under "Print Receipt" column.
            </Typography>
          </Box>

          <CommonTable
            columnData={paymentHistoryColumns}
            tableData={paymentHistoryData}
          />
        </Card>
      </Box>
    </>
  );
}
