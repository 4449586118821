import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TextField, Box } from "@mui/material";
import { cssProperties } from "../utils/commonCssProperties";

export default function FilterTable({ tableData, columnData }) {
  let [rows, setRows] = React.useState(tableData);

  React.useEffect(() => {
    setRows(tableData);
  }, [tableData]);

  // Handler to update row data when the form input changes
  const handleInputChange = (event, rowIndex, field) => {
    const newRows = [...rows];
    newRows[rowIndex][field] = event.target.value;
    setRows(newRows);
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "",
          width: "100%",
          mb: 2,
          pr: 2,
          mt: 4,
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            background: "none",
            border: `0px solid ${cssProperties.bordercolor.primary2blue}`,
            boxShadow: "none",
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="vertical table">
            <TableBody>
              {rows.length > 0 ? (
                <>
                  {/* Row 1: Application No and DOB */}
                  <TableRow
                    sx={{
                      border: `1px solid ${cssProperties.bordercolor.primary2blue}`,
                    }}
                  >
                    <TableCell
                      sx={{
                        textAlign: "end",
                        color: cssProperties.bordercolor.tableblack,
                        fontWeight: "500",
                        width: 400,
                        border: `1px solid ${cssProperties.bordercolor.primary2blue}`,
                      }}
                    >
                      Application No
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "start",
                        color: cssProperties.bordercolor.tableblack,
                        border: `1px solid ${cssProperties.bordercolor.primary2blue}`,
                        width:'20%'
                      }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        value={rows[0]["applicationNo"] || ""}
                        onChange={(e) =>
                          handleInputChange(e, 0, "applicationNo")
                        }
                        sx={{ width: "100%" }}
                      />
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "end",
                        color: cssProperties.bordercolor.tableblack,
                        fontWeight: "500",
                        width: 400,
                        border: `1px solid ${cssProperties.bordercolor.primary2blue}`,
                         width:'20%'
                      }}
                    >
                      DOB (DD/MM/YYYY)
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "start",
                        color: cssProperties.bordercolor.tableblack,
                        border: `1px solid ${cssProperties.bordercolor.primary2blue}`,
                      }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        value={rows[0]["dob"] || ""}
                        onChange={(e) => handleInputChange(e, 0, "dob")}
                        sx={{ width: "100%" }}
                      />
                    </TableCell>
                  </TableRow>

                  {/* Row 2: Candidate's Name */}
                  <TableRow
                    sx={{
                      border: `1px solid ${cssProperties.bordercolor.primary2blue}`,
                    }}
                  >
                    <TableCell
                      sx={{
                        textAlign: "end",
                        color: cssProperties.bordercolor.tableblack,
                        fontWeight: "500",
                        width: 400,
                        border: `1px solid ${cssProperties.bordercolor.primary2blue}`,
                      }}
                    >
                      Candidate's Name
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "start",
                        color: cssProperties.bordercolor.tableblack,
                      }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        value={rows[0]["candidateName"] || ""}
                        onChange={(e) =>
                          handleInputChange(e, 0, "candidateName")
                        }
                        sx={{ width: "150%" }}
                      />
                    </TableCell>
                  </TableRow>

                  {/* Row 3: Mobile No and E-Mail ID */}
                  <TableRow
                    sx={{
                      border: `1px solid ${cssProperties.bordercolor.primary2blue}`,
                    }}
                  >
                    <TableCell
                      sx={{
                        textAlign: "end",
                        color: cssProperties.bordercolor.tableblack,
                        fontWeight: "500",
                        width: 400,
                        border: `1px solid ${cssProperties.bordercolor.primary2blue}`,
                      }}
                    >
                      Mobile No
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "start",
                        color: cssProperties.bordercolor.tableblack,
                        border: `1px solid ${cssProperties.bordercolor.primary2blue}`,
                      }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        value={rows[0]["mobileNo"] || ""}
                        onChange={(e) =>
                          handleInputChange(e, 0, "mobileNo")
                        }
                        sx={{ width: "100%" }}
                      />
                    </TableCell>

                    <TableCell
                      sx={{
                        textAlign: "end",
                        color: cssProperties.bordercolor.tableblack,
                        fontWeight: "500",
                        width: 400,
                        border: `1px solid ${cssProperties.bordercolor.primary2blue}`,
                      }}
                    >
                      E-Mail ID
                    </TableCell>
                    <TableCell
                      sx={{
                        textAlign: "start",
                        color: cssProperties.bordercolor.tableblack,
                        border: `1px solid ${cssProperties.bordercolor.primary2blue}`,
                      }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        value={rows[0]["emailId"] || ""}
                        onChange={(e) =>
                          handleInputChange(e, 0, "emailId")
                        }
                        sx={{ width: "100%" }}
                      />
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    sx={{
                      textAlign: "center",
                      color: cssProperties.bordercolor.primary2blue,
                    }}
                  >
                    No Data
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
