import { Box, Typography } from "@mui/material";
import React from "react";
import FilterTable from "../../common/filterTable";
import BluePrimaryButton from "../../common/button/bluePrimaryButton";
import CommonTable from "../../common/CommonTable";

export default function Index() {
  let candidateColumns = [
    {
      name: "Application No",
      dataIndex: "applicationNo",
    },
    {
      name: "DOB (DD/MM/YYYY)",
      dataIndex: "dob",
    },
    {
      name: "Candidate's Name",
      dataIndex: "candidateName",
    },
    {
      name: "Mobile No",
      dataIndex: "mobileNo",
    },
    {
      name: "E-Mail ID",
      dataIndex: "emailId",
    },
  ];

  const candidateData = [
    {
      applicationNo: "",
      dob: "",
      candidateName: "",
      mobileNo: "",
      emailId: "",
    },
  ];

  let candidateInfoColumns = [
    {
      name: "Application No",
      dataIndex: "applicationNo",
    },
    {
      name: "Candidate's Name",
      dataIndex: "candidateName",
    },
    {
      name: "E-Mail ID",
      dataIndex: "emailId",
    },
    {
      name: "DOB",
      dataIndex: "dob",
    },

    {
      name: "Application Status",
      dataIndex: "applicationStatus",
    },
  ];

  const candidatesData = [
    {
      applicationNo: 244700005,
      candidateName: "Nishant Janbandhu",
      emailId: "test@gmail.com",
      dob: "07/10/2001",
      applicationStatus: "Application Form Complete And Confirm",
    },
  ];

  return (
    <>
      <Box
        sx={{
          margin: "1rem auto",
          backgroundColor: "rgba(255, 255, 255, 1)",
          borderRadius: "8px",
          padding: "10px",
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "500",
              color: "black",
              fontSize: "20px",
              lineHeight: "24px",
              mb: 2,
              backgroundColor: "whitesmoke",
              padding: "8px",
              borderRadius: "4px",
            }}
          >
            Search Candidate{" "}
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography
            variant="subtitle1"
            sx={{ color: "red", fontWeight: "bold" }}
          >
            Instructions :
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "500",
              color: "red",
              fontSize: "16px",
              lineHeight: "24px",
              mb: 2,
              backgroundColor: "whitesmoke",
              border: "1px solid red",
              padding: "8px",
              borderRadius: "4px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box display="flex" alignItems="center">
              <span style={{ fontSize: "24px", marginRight: "8px" }}>•</span>
              Please Enter Application No and/or Candidate's Name and/or DOB
              and/or Mobile No and/or E-mail Id to Search Candidate
            </Box>

            <Box display="flex" alignItems="center" marginTop="4px">
              <span style={{ fontSize: "24px", marginRight: "8px" }}>•</span>
              Please Enter atleast one parameter
            </Box>
          </Typography>
        </Box>
        <FilterTable columnData={candidateColumns} tableData={candidateData} />
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <BluePrimaryButton title="Search Candidate" />
        </Box>{" "}
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "500",
            color: "black",
            fontSize: "20px",
            lineHeight: "24px",
            mb: 2,
            backgroundColor: "whitesmoke",
            padding: "8px",
            borderRadius: "4px",
          }}
        >
          Candidate Details
        </Typography>
      </Box>

      <Box
        sx={{
          margin: "1rem auto",
          backgroundColor: "rgba(255, 255, 255, 1)",
          borderRadius: "8px",
          padding: "10px",
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography
            variant="subtitle1"
            sx={{ color: "red", fontWeight: "bold" }}
          >
            Instructions :
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "500",
              color: "red",
              fontSize: "16px",
              lineHeight: "24px",
              mb: 2,
              backgroundColor: "whitesmoke",
              border: "1px solid red",
              padding: "8px",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: "24px", marginRight: "8px" }}>•</span>
            Please Click on Application No. to Print Application Form.
          </Typography>
        </Box>
        <CommonTable
          columnData={candidateInfoColumns}
          tableData={candidatesData}
        />
      </Box>
    </>
  );
}
